import React, { useReducer } from 'react';

const LOCALSTORAGE_KEY = 'fotokorner_cart';

export const ORDER_TYPE_DOWNLOAD = 'DOWNLOAD';
export const ORDER_TYPE_SELECTION = 'SELECTION';

export const CartContext = React.createContext(null);

const persistedState =
  typeof window !== 'undefined'
    ? localStorage?.getItem(LOCALSTORAGE_KEY)
    : null;
const initialState = JSON.parse(persistedState) || {
  selection: [],
  orderId: null,
  orderType: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      // Find image index in cart
      const index = state.selection.findIndex((i) => i.id === action.id);

      // If image not in cart, add it with quantity = 1
      if (index === -1) {
        return {
          ...state,
          selection: [
            ...state.selection,
            {
              id: action.id,
              galleryId: action.galleryId,
              quantity: 1,
            },
          ],
        };
      }

      // If image already in cart, increment quantity
      const current = state.selection[index];
      return {
        ...state,
        selection: [
          ...state.selection.slice(0, index),
          {
            ...current,
            quantity: current.quantity + 1,
          },
          ...state.selection.slice(index + 1),
        ],
      };
    }
    case 'remove': {
      // Find image index in cart
      const index = state.selection.findIndex((i) => i.id === action.id);

      // If not in cart, do nothing
      if (index === -1) {
        return state;
      }

      const current = state.selection[index];

      // If quantity = 1, remove it from cart
      if (current.quantity === 1) {
        return {
          ...state,
          selection: [
            ...state.selection.slice(0, index),
            ...state.selection.slice(index + 1),
          ],
        };
      }

      // If quantity > 1, decrement it
      return {
        ...state,
        selection: [
          ...state.selection.slice(0, index),
          {
            ...current,
            quantity: current.quantity - 1,
          },
          ...state.selection.slice(index + 1),
        ],
      };
    }
    case 'empty': {
      return {
        ...state,
        selection: [],
      };
    }
    case 'set_order_id': {
      return {
        ...state,
        orderId: action.id,
      };
    }
    case 'set_order_type': {
      return {
        ...state,
        orderType: action.id,
      };
    }
    case 'cancel_order': {
      return {
        ...state,
        orderId: null,
        orderType: null,
      };
    }
    default:
      throw new Error();
  }
};

const persistor = (action, type) => {
  const newState = reducer(action, type);
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newState));
  return newState;
};

const CartProvider = ({ children }) => {
  const cart = useReducer(persistor, initialState);
  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

export default CartProvider;
